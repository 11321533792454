import Vue from "vue";
import { Efectivo } from "apd.sistemapagos.models";
import { mdiCash, mdiCurrencyUsdCircle } from "@mdi/js";
import { AxiosHttpClient, ApiErrorResponse, ApiSistemaPagos } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";

interface TableCash {
  id: number;
  cash: Efectivo;
}

export default Vue.extend({
  name: "ToneleroCardComponent",
  props: {
    idKiosko: {
      type: String,
      required: true,
    },
    kiosko: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false as boolean,
      efectivo: [] as Efectivo[],
      isError: false as boolean,
      mensaje: "" as string,
      icons: { coin: mdiCurrencyUsdCircle, bill: mdiCash } as Record<string, string>,
    };
  },
  computed: {
    efectivoOrdenado(): Efectivo[] {
      return this.efectivo.slice().sort((a, b) => {
        if (a.denominacion != undefined && b.denominacion != undefined) {
          if (a.denominacion < b.denominacion) {
            return -1;
          }
          if (a.denominacion > b.denominacion) {
            return 1;
          }
        }
        return 0;
      });
    },
    datos(): TableCash[] {
      if (this.efectivoOrdenado == null || this.efectivoOrdenado.length <= 0) {
        return [];
      } else {
        const d: TableCash[] = [];
        let index = 1;

        this.efectivoOrdenado.forEach((item) => {
          d.push({ id: index, cash: item as Efectivo });
          index++;
        });

        return d;
      }
    },
  },
  created() {
    this.loading = true;

    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
    );
    axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    apiSP
      .ConsultarEfectivoDisponible(this.idKiosko)
      .then((resp) => {
        this.efectivo = resp as Efectivo[];
      })
      .catch((resp) => {
        const err = resp as ApiErrorResponse;
        this.isError = true;
        this.mensaje = err.apiError.mensajeUsuario;
      })
      .finally(() => {
        this.loading = false;
      });
  },
});
